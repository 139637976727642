<template>
  <div class="illegal-release-list">
    <pageHeaderView></pageHeaderView>
    <Card class="content">
      <div class="card-header">
        <div class="card-header-text">重大欠薪违法行为公开列表</div>
        <div class="card-header-divider"></div>
      </div>
      <div class="box-header">
        <div class="box-header-left">
          <city-select-list :city="region" @selectCity="selectCity"></city-select-list>
        </div>
        <div class="box-tools">
          <div style="display: inline-block;margin-right:15px;" @mouseenter="toggleSearch(true)" @mouseleave="toggleSearch(false)">
            <Select v-model="searchBy" class="table-search" :class="{on: tableSearchOn||searchMode||searchBy}">
              <Option value="">不限</Option>
              <Option :value="col.searchKey?col.searchKey:col.key" v-for="col in columns.filter((c)=>{return c.title && c.searchable})" :key="col.key">{{col.title}}</Option>
            </Select>&nbsp;
            <Input v-model="keyword" ref="tableSearch" placeholder="搜索关键字" @on-focus="toggleSearchMode" @on-blur="toggleSearchMode" class="table-search" :class="{on: tableSearchOn||searchMode||keyword}"></Input>&nbsp;
            <Button type="default" icon="ios-search" @click="search"></Button>
          </div>
          <Button-group>
            <Button type="default" icon="md-add" @click="$router.push('/illegal-release/add')">新增</Button>
            <Button type="default" icon="md-refresh" @click="refreshList">刷新</Button>
          </Button-group>
        </div>
      </div>
      <div class="box-body">
        <Table stripe :columns="columns" :data="data" ref="table" @on-selection-change="selectRowChange"></Table>
        <div style="margin: 10px;overflow: hidden">
          <Button type="primary" size="large" @click="exportData" :disabled="!rowSelected"><Icon type="ios-download-outline"></Icon> 导出原始数据</Button>
          <div style="float: right;">
            <Page :total="totalCount" :page-size-opts="[10,20,50,100]" @on-change="changePage" @on-page-size-change="changePageSize" :current="pageCurrent" show-sizer></Page>
          </div>
        </div>
        <Modal v-model="modalDetail" @on-cancel="closeDetailModal" width="720">
          <p slot="header">
            <Icon type="information-circled"></Icon>
            <span>查看详情</span>
          </p>
          <div class="detail-view" v-if="dataSelected&&dataSelected.IllegalSubject">
            <table class="detail-table">
              <tr>
                <th width="200">区域</th>
                <td>{{ dataSelected.RegionFullName }}</td>
              </tr>
              <tr>
                <th width="200">重大违法责任主体</th>
                <td>{{ dataSelected.IllegalSubject }}</td>
              </tr>
              <tr>
                <th width="200">监管部门</th>
                <td>{{ dataSelected.SupervisorName }}</td>
              </tr>
              <tr>
                <th width="200">违法时间</th>
                <td>{{ $moment(dataSelected.IllegalDate).format('YYYY-MM-DD') }}</td>
              </tr>
              <tr>
                <th width="200">违法内容</th>
                <td>{{ dataSelected.IllegalContent }}</td>
              </tr>
              <tr>
                <th width="200">上报人</th>
                <td>{{ dataSelected.Operator }}</td>
              </tr>
            </table>
          </div>
        </Modal>
        <Modal v-model="modalDelete" width="360">
          <p slot="header" style="color:#f60;text-align:center">
            <Icon type="information-circled"></Icon>
            <span>删除确认</span>
          </p>
          <div style="text-align:center">
            <p>是否继续删除？</p>
          </div>
          <div slot="footer">
            <Button type="error" size="large" long :loading="modal_loading" @click="del">删除</Button>
          </div>
        </Modal>
      </div>
    </Card>
  </div>
</template>

<script>
import pageHeaderView from '../public/PageHeader'
import citySelectList from '../public/CitySelectList'
export default {
  name: 'illegal-release-list',
  components: {
    pageHeaderView,
    citySelectList
  },
  data () {
    return {
      datatableData: [],
      loading: false,
      dataSelected: null,
      modal_loading: false,
      modalDelete: false,
      modalDetail: false,
      rowSelected: false,
      rowSelectedList: [],
      pageCurrent: 1,
      pageSize: 10,
      keyword: '',
      searchBy: '',
      searchMode: false,
      tableSearchOn: false,
      columns: [
        { type: 'selection', width: 60, align: 'center' },
        {
          title: '区域',
          key: 'RegionFullName',
          width: 240,
          ellipsis: true
        },
        {
          title: '重大违法责任主体',
          key: 'IllegalSubject',
          width: 240,
          ellipsis: true,
          searchable: true
        },
        {
          title: '违法内容',
          key: 'IllegalContent',
          render: (h, params) => {
            let content = this.getContent(params.row)
            return h('span', {
              class: 'font-grey'
            }, content)
          }
        },
        {
          title: '违法时间',
          key: 'IllegalDate',
          width: 160,
          render: (h, params) => {
            return h('span', null, this.$moment(params.row.IllegalDate).format('YYYY-MM-DD'))
          }
        },
        {
          title: '监管部门',
          key: 'SupervisorName',
          width: 240,
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 180,
          render: (h, params) => {
            return h('Button-group', [
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.dataSelected = params.row
                    this.modalDetail = true
                    // this.$router.push({ path: '/complaint/view/' + params.row.Id })
                  }
                }
              }, '详情'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.dataSelected = params.row
                    localStorage.setItem('illegal-release-current', JSON.stringify(params.row))
                    this.$router.push({ path: '/illegal-release/edit/' + params.row.Tid })
                  }
                }
              }, '修改'),
              h('Button', {
                props: {
                  type: 'default',
                  size: 'small'
                },
                style: {
                },
                on: {
                  click: () => {
                    this.dataSelected = params.row
                    this.modalDelete = true
                  }
                }
              }, '删除'),
            ])
          }
        }
      ],
      data: [],
      region: null,
      province: 0,
      municipality: 0,
      county: 0,
      totalCount: 0,
      pageTotal: 0,
    }
  },
  watch: {
  },
  mounted () {
    this.$store.dispatch('setPageStore', {
      pageHeader: '重大欠薪违法行为公开列表',
      pageDescr: '全部列表'
    })
    this.initDataTable()
  },
  methods: {
    selectCity (value) {
      console.log(value)
      this.region = value
      if (value && value.length > 0) {
        this.province = parseInt(value.substr(0, 2))
        this.municipality = parseInt(value.substr(2, 2))
        this.county = parseInt(value.substr(4, 2))
        this.initDataTable()
      } else {
        this.province = 0
        this.municipality = 0
        this.county = 0
        this.initDataTable()
      }
    },
    initDataTable (query = {}) {
      let queryModel = {
        PageIndex: this.pageCurrent,
        PageSize: this.pageSize,
        Province: this.province,
        Municipality: this.municipality,
        County: this.county
      }
      Object.assign(queryModel, query)
      this.$Spin.show()
      this.$Api.Project.getIllegalReleaseList(queryModel).then((respBody) => {
        this.pageTotal = respBody.PageCount
        this.totalCount = respBody.TotalCount
        this.$Spin.hide()
        this.data = respBody.List
      })
      .catch(err => {
        this.$Spin.hide()
        this.$Notice.error({
          title: '获取列表失败!',
          desc: err.message
        })
      })
    },
    getContent (row) {
      let content = ''
      if (row.IllegalContent.length >= 30) {
        content = row.IllegalContent.substr(0, 30) + '...'
      } else {
        content = row.IllegalContent
      }
      return content
    },
    closeComplaintDetail () {
      this.modalDetail = false
    },
    selectRowChange (selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage (page) {
      this.pageCurrent = page
      this.initDataTable()
    },
    changePageSize (pageSize) {
      this.pageSize = pageSize
      this.initDataTable()
    },
    exportData () {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList
      })
    },
    async del () {
      this.modal_loading = true
      await this.$Api.Project.deleteIllegalRelease(this.dataSelected.Tid)
        .then(() => {
          this.$Message.success('删除成功')
          let position = this.data.findIndex(o => o.Tid === this.dataSelected.Tid)
          this.data.splice(position, 1)
        })
        .catch((err) => {
          this.$Message.error('删除失败！' + err.message)
        })
      this.modal_loading = false
      this.modalDelete = false
    },
    refreshList () {
      // refreshList()
      this.initDataTable()
      this.searchBy = ''
      this.keyword = ''
      this.searchMode = false
      this.tableSearchOn = false
    },
    toggleSearchMode () {
      this.searchMode = !this.searchMode
    },
    toggleSearch (val) {
      this.tableSearchOn = val
    },
    search () {
      if (this.searchBy) {
        this.pageCurrent = 1
        let query = {}
        query[this.searchBy] = this.keyword
        this.initDataTable(query)
      }
    },
    closeDetailModal () {
      this.modalDetail = false
      this.dataSelected = null
    }
  }
}
</script>

<style lang="less" scoped>
.detail-view {
  .detail-line {
    display: block;
    .title {
      display: inline-block;
    }
    .value {
      display: inline;
    }
  }
}
</style>
